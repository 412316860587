import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ImmunizationMMRView.module.css";

import { AddButton, InputBox } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const SubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const SubmissionTypeDescriptions = {
  A: "A. Vaccination Dates:",
  B: "B. Immunity by Titer:",
  Military: "Military immunization",
};

export default function ImmunizationMMRView({
  data = {},
  onChange,
  disabled = false
}) {
  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  console.log("ImmunizationMMRView")

  return (
    <>
      <div className={styles.container}>
        <div>
          {/* {(data.type === SubmissionTypes.Military) && ( */}
            <DashboardRadio
              isSelected={data.type === SubmissionTypes.Military}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={SubmissionTypes.Military}
              label={SubmissionTypeDescriptions.Military}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
        </div>
        <div>
          {/* {(data.type === SubmissionTypes.A) && ( */}
            <DashboardRadio
              isSelected={data.type === SubmissionTypes.A}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={SubmissionTypes.A}
              label={SubmissionTypeDescriptions.A}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
          {data.type === SubmissionTypes.A && (
            <div className={styles.testRow}>
              <div className={styles.testRow}>
                1.{" "}
                <FormDatePicker
                  dateFormat="MM/dd/yyyy"
                  dateString={data.date1}
                  setDate={(value) => {
                    data.date1 = value;
                    setValue();
                  }}
                  disabled={disabled}
                />
              </div>
              <div className={styles.testRow}>
                2.{" "}
                <FormDatePicker
                  dateFormat="MM/dd/yyyy"
                  dateString={data.date2}
                  setDate={(value) => {
                    data.date2 = value;
                    setValue();
                  }}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {/* {(data.type === SubmissionTypes.B) && ( */}
            <DashboardRadio
              isSelected={data.type === SubmissionTypes.B}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={SubmissionTypes.B}
              label={SubmissionTypeDescriptions.B}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
          {data.type === SubmissionTypes.B && (
            <div>
              <div className={styles.testRow}>
                <div className={styles.testItem}>
                  <label>Measles by titer:</label>
                  <div className={styles.testItem2}>
                    <FormDatePicker
                      dateFormat="MM/dd/yyyy"
                      dateString={data.measlesDate}
                      setDate={(value) => {
                        data.measlesDate = value;
                        setValue();
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.testRow}>
                <div className={styles.testItem}>
                  <label>Mumps by titer:</label>
                  <div className={styles.testItem2}>
                    <FormDatePicker
                      dateFormat="MM/dd/yyyy"
                      dateString={data.mumpsDate}
                      setDate={(value) => {
                        data.mumpsDate = value;
                        setValue();
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.testRow}>
                <div className={styles.testItem}>
                  <label>Rubella by titer:</label>
                  <div className={styles.testItem2}>
                    <FormDatePicker
                      dateFormat="MM/dd/yyyy"
                      dateString={data.rubellaDate}
                      setDate={(value) => {
                        data.rubellaDate = value;
                        setValue();
                      }}
                      disabled={disabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
