import React, { useCallback, useRef, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./InsuranceView.module.css";

import { AddButton, InputBox, CheckboxItem } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

export const SubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const SubmissionTypeDescriptions = {
  A: "Insurance verified by program",
  B: "Individual Insurance",
  Military: "Military immunization",
};

const InsuranceView = forwardRef(({
  data = {},
  onChange,
  disabled = false,
  onValidate }, ref) => {

  const [currentExpirationDate, setCurrentExpirationDate] = useState(data.expirationDate);
  const [error, setError] = useState('');

  useImperativeHandle(ref, () => ({
    validate: () => {
      const errors = {};
      if (data.type == SubmissionTypes.B && !data.expirationDate) {
        errors.expirationDate = 'Expiration Date is required';
        setError(errors.expirationDate);
        onValidate(errors.expirationDate);
        return errors; // Return error to parent
      }
      setError('');
      onValidate({});
      return null; // No errors
    },
  }));

  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
      if (value == SubmissionTypes.A) {
        data.expirationDate = null;
      } else {
        data.expirationDate = currentExpirationDate;
      }
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.testRow}>
          <DashboardRadio
            isSelected={data.type === SubmissionTypes.A}
            onChange={(value) => {
              setValue("type", value);
            }}
            value={SubmissionTypes.A}
            label={SubmissionTypeDescriptions.A}
            containerClassName={styles.radioContainer}
            disabled={disabled}
          />
        </div>
        <div className={styles.testRow}>
          <DashboardRadio
            isSelected={data.type === SubmissionTypes.B}
            onChange={(value) => {
              setValue("type", value);
            }}
            value={SubmissionTypes.B}
            label={SubmissionTypeDescriptions.B}
            containerClassName={styles.radioContainer}
            disabled={disabled}
          />
        </div>
        {data.type == SubmissionTypes.B && (
          <div className={styles.testRow}>
            <label>Expiration Date:</label>
            <div className={styles.testItem2}>
              <FormDatePicker
                dateFormat="MM/dd/yyyy"
                dateString={data.expirationDate}
                setDate={(value) => {
                  data.expirationDate = value;
                  setCurrentExpirationDate(value)
                  setValue();
                }}
                disabled={disabled}
              />
            </div>
            {error && <div className={styles.errorMessages}>{error}</div>}
          </div>
        )}
      </div>
    </>
  );
})

export default InsuranceView;
