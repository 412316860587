import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./PassportRequirementSubmissions.module.css";

import { HeaderSection } from "@cpnw/ui";
import PassportStudentInfo from "components/PassportStudentInfo";

// import DashboardClinicalAssignmentTable from 'components/DashboardClinicalAssignmentTable';
import DashboardClinicalAssignmentItem from "components/DashboardClinicalAssignmentItem";

import PassportStudentPhoto from "components/PassportStudentPhoto/PassportStudentPhoto";
import PassportRecordRequirementTable from "components/PassportRecordRequirementTable";
import { Modal, Button } from "@cpnw/ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faBan,
  faStarOfLife,
  faHourglassStart,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import PassportRequirementReviewerView from "components/PassportRequirementReviewerView";
import { RequirementContainer } from "hooks/useRequirement";
import { format } from "date-fns";
import { AccountContainer } from "hooks/useAccount";

export default function PassportRequirementSubmissions({
  modalIsOpen,
  onModalClose,
  studentInfo,
  studentPhoto,
  assignmentsInfo,
  contactId,
  canCompleteVerification = true,
  onCompleteVerification,
}) {
  const { useStudentRequirements } = RequirementContainer.useContainer();
  const { userRoles } = AccountContainer.useContainer();
  const roles = userRoles.map((a) => a.toUpperCase());

  const {
    data: userRequirementsData,
    isLoading,
    refetch: refetchUserRequirements,
  } = useStudentRequirements(contactId);

  const columnWidths = ["40%", "30%", "30%"]; // Adjust widths as needed
  const [modalOpen, setModalOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isVerificationCompleteModalOpen, setIsVerificationCompleteModalOpen] =
    useState(false);

  const data = useMemo(() => {
    const canViews = ["Submitted", "Approved", "Rejected", "In Review"];
    if (userRequirementsData && userRequirementsData.data.length > 0) {
      return userRequirementsData.data.flatMap((a) => {
        return {
          id: a.ContactRequirementId,
          reqName: a.Name,
          src: "What is this?",
          freq: a.Frequency,
          type: a.Category.Name,
          status: a.Status,
          expiration: a.Expiration || "",
          reviewer: a.Reviewer || "",
          score: a.Score ? a.Score.Score : null,
          canReview: a.CanReview,
          // canEdit: a.CanEdit,
          // canView: a.CanView,
          canView:
            roles.includes("EDUCATION") ||
            (canViews.includes(a.Status) && !a.Category.HasModule),
          dueDate: a.DueDate ? format(a.DueDate, "MM/dd/yyyy") : "",
          createdByTypeContact: a.CreatedByTypeContact,
        };
      });
    }
    return [];
  }, [userRequirementsData, roles]);

  const closeModal = () => {
    setModalOpen(false);
    onModalClose();
  };

  const onItemView = useCallback(
    async (item) => {
      console.log("onItemView", item);
      const requirement = userRequirementsData.data.find(
        (a) => a.ContactRequirementId == item.id
      );

      if (requirement.Category.HasModule) {
        //eLearning, open takeQuiz
        // const data = await takeQuiz(requirement.Module.Id);
        // const url = data.url;
        // console.log("url", url);
        // window.open(url, "_blank");
      } else {
        (requirement.Submissions || []).forEach((sub) => {
          sub.files = (sub.Files || []).map((f) => ({
            id: f.Id,
            name: f.Name,
            url: f.Url,
          }));
        });

        const modalData = {
          contactId: contactId,
          contactRequirementId: requirement.ContactRequirementId,
          requirementName: requirement.Name,
          type: requirement.Category,
          abbreviation: requirement.Abbr,
          requiredBy: requirement.RequiredBy.map((a) => a.FacilityName),
          frequency: requirement.Frequency,
          instructions: requirement.Instructions || "",
          status: requirement.Status || "",
          requirementFiles: (requirement.RequirementFiles || []).map((a) => ({
            id: a.Id,
            name: a.Name,
            url: a.Url,
          })),
          messages: requirement.Messages || [],
          allowWaiver: requirement.AllowWaiver,
          isWaived: requirement.IsWaived,
          score: requirement.Score ? requirement.Score.Score : null,
          submissions: requirement.Submissions,
          submission:
            requirement.Submissions.length > 0
              ? requirement.Submissions[0]
              : {},
          hasLicenses: requirement.HasLicenses,
          expiresOn: requirement.ExpiresOn,
          canReview: requirement.CanReview,
          canEdit: requirement.CanEdit,
          canView: requirement.CanView,
          subCategory: requirement.SubCategory,
          isEducationRole: roles.includes("EDUCATION"),
        };

        console.log("modalData", modalData);

        setIsModalOpen(true);
        setModalContent(modalData);
      }
    },
    [userRequirementsData]
  );

  const onSaveComplete = () => {
    setIsModalOpen(false);
    setModalContent(null);
    refetchUserRequirements();
  };

  return (
    // <>
    <Modal
      isOpen={modalOpen}
      onRequestClose={() => closeModal()}
      className={styles.modal_content}
      modalClassName={styles.modal_wrapper}
    >
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className={styles.modal_content}
        modalClassName={styles.modal_wrapper}
      >
        <div className={styles.admin_review_modal}>
          <div className={styles.close_modal_btn}>
            <FontAwesomeIcon
              icon={faXmark}
              style={{
                fontSize: "28px",
                transform: "translateY(-15px) translateX(15px)",
                color: "#808080",
                cursor: "pointer",
              }}
              onClick={() => setIsModalOpen(false)}
            />
          </div>
          {modalContent && (
            <PassportRequirementReviewerView
              studentContactID={contactId}
              requirement={modalContent}
              onSaveComplete={onSaveComplete}
            />
          )}
        </div>
      </Modal>
      <Modal
        isOpen={isVerificationCompleteModalOpen}
        onRequestClose={() => {
          setIsVerificationCompleteModalOpen(false);
        }}
        className={classNames([])}
      >
        <h3>Verification Complete</h3>
        <ul className={styles.warningList}>
          <li>
            This action will make all submissions of this student visible to
            Healthcare coordinator.
          </li>
        </ul>
        <div>Do you want to continue?</div>
        <div className={styles.warningButtons}>
          <Button
            text={"YES"}
            type={"submit"}
            className={styles.customButton}
            onClick={(ev) => {
              onCompleteVerification();
              setIsVerificationCompleteModalOpen(false);
            }}
          />
          <Button
            text={"NO"}
            type={"cancel"}
            className={styles.customButton}
            onClick={() => {
              setIsVerificationCompleteModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <div className={styles.close_modal_btn}>
        <FontAwesomeIcon
          icon={faXmark}
          style={{
            fontSize: "28px",
            transform: "translateY(-15px) translateX(15px)",
            color: "#808080",
            cursor: "pointer",
          }}
          onClick={() => {
            closeModal();
          }}
        />
      </div>
      <div className={styles.submissions_wrapper}>
        <HeaderSection header={"Requirement Submissions"} />

        <div className={classNames(styles.row, styles.info_photo)}>
          <div className={classNames(styles.column, styles.student_info)}>
            <PassportStudentInfo student={studentInfo} />
          </div>
          <div className={classNames(styles.column, styles.stud_photo)}>
            <PassportStudentPhoto
              image={
                studentPhoto && studentPhoto.image ? studentPhoto.image : null
              }
              downloadFileName={
                studentPhoto && studentPhoto.downloadFileName
                  ? studentPhoto.downloadFileName
                  : null
              }
            />
          </div>
        </div>

        {/* The Clinical Assignments Table component has "my clinical assignments as the header", so I just pulled the logic and styles from it to here in order to adjust dom element */}
        <div className={styles.clin_assignment_wrapper}>
          <div className={styles.assignmentTable}>
            <h3 className={styles.title}>Clinical Assignments</h3>
            <div className={styles.table}>
              <div className={styles.header}>
                <div
                  className={styles.headerCell}
                  style={{ width: columnWidths[0] }}
                >
                  Location
                </div>
                <div
                  className={styles.headerCell}
                  style={{ width: columnWidths[1] }}
                >
                  Start Date
                </div>
                <div
                  className={styles.headerCell}
                  style={{ width: columnWidths[2] }}
                >
                  End Date
                </div>
              </div>
              {assignmentsInfo.map((assignment, index) => (
                <DashboardClinicalAssignmentItem
                  key={index}
                  assignment={assignment}
                  columnWidths={columnWidths}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={styles.record_table}>
          <h3 className={styles.title}>Records</h3>
          {/* if using this table structure, key is needed */}
          <div className={styles.key}>
            <div className={styles.key_item}>
              <p className={styles.bold}>Key:</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: "#007e8d",
                  fontSize: "20px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Approved</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faBan}
                style={{
                  color: "red",
                  fontSize: "16px",
                  rotate: "90deg",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Rejected</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faStarOfLife}
                style={{
                  color: "#aa9b73",
                  fontSize: "16px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Waiver</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faHourglassStart}
                style={{
                  color: "red",
                  fontSize: "16px",
                  rotate: "180deg",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Expired</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faHourglassHalf}
                style={{
                  color: "#aa9b73",
                  fontSize: "16px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Expiring</p>
            </div>
          </div>

          <PassportRecordRequirementTable
            items={data || []}
            onItemClick={onItemView}
            isLoading={isLoading}
          />

          {canCompleteVerification && (
            <div className={styles.center}>
              <Button
                onClick={() => {
                  setIsVerificationCompleteModalOpen(true);
                }}
                text="Verification Complete"
                disabled={false}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

PassportRequirementSubmissions.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,

  studentInfo: PropTypes.shape({
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    school: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    sidEid: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    //emergency contact
    eContact: PropTypes.string.isRequired,
    eContactPhone: PropTypes.string.isRequired,
  }).isRequired,

  studentPhoto: PropTypes.shape({
    image: PropTypes.string,
    downloadFileName: PropTypes.string,
  }),

  assignmentsInfo: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
    })
  ).isRequired,

  recordInfo: PropTypes.arrayOf(
    PropTypes.shape({
      reqName: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      freq: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      expiration: PropTypes.string.isRequired,
      reviewer: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
