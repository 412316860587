import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ImmunizationTBView.module.css";

import { AddButton, InputBox } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const TBSubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
};

const TBSubmissionTypeDescriptions = {
  A: "A. Two Step",
  B: "B. TB IGRA (blood test)",
  C: "C. If Past, or new positive, history of BCG vaccine",
};

const TBTestResults = {
  Neg: "Neg",
  Pos: "Pos",
};

export default function ImmunizationTBView({
  data = {
    test1: {},
    test2: {},
  },
  onChange,
  disabled = false
}) {
  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div>
          {/* {(data.type === TBSubmissionTypes.A) && ( */}
            <DashboardRadio
              isSelected={data.type === TBSubmissionTypes.A}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={TBSubmissionTypes.A}
              label={TBSubmissionTypeDescriptions.A}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
          {data.type === TBSubmissionTypes.A && (
            <div className={styles.testsContainer}>
              <div className={styles.testContainer}>
                <div>Test #1</div>
                <div className={styles.testRow}>
                  <div className={styles.testItem}>
                    <label>Place Date</label>
                    <div className={styles.testItem2}>
                      <FormDatePicker
                        dateFormat="MM/dd/yyyy"
                        dateString={data.test1.placeDate}
                        setDate={(value) => {
                          data.test1.placeDate = value;
                          setValue();
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className={styles.testItem}>
                    <label>Read Date</label>
                    <div className={styles.testItem2}>
                      <FormDatePicker
                        dateFormat="MM/dd/yyyy"
                        dateString={data.test1.readDate}
                        setDate={(value) => {
                          data.test1.readDate = value;
                          setValue();
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.testRow}>
                  <div className={styles.testItem}>
                    <label>Result in mm</label>
                    <div className={styles.testItem2}>
                      <InputBox
                        placeholder={""}
                        onChange={(e) => {
                          data.test1.resultText = e.target.value;
                          setValue();
                        }}
                        value={data.test1.resultText}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className={styles.testItem}>
                    <label>Result</label>
                    <div className={styles.testItem2}>
                      <DashboardRadio
                        isSelected={data.test1.result === TBTestResults.Neg}
                        onChange={(value) => {
                          data.test1.result = value;
                          setValue();
                        }}
                        value={TBTestResults.Neg}
                        label={TBTestResults.Neg}
                        containerClassName={styles.radioContainer}
                        disabled={disabled}
                      />
                      <DashboardRadio
                        isSelected={data.test1.result === TBTestResults.Pos}
                        onChange={(value) => {
                          data.test1.result = value;
                          setValue();
                        }}
                        value={TBTestResults.Pos}
                        label={TBTestResults.Pos}
                        containerClassName={styles.radioContainer}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.testContainer}>
                <div>Test #2</div>
                <div className={styles.testRow}>
                  <div className={styles.testItem}>
                    <label>Place Date</label>
                    <div className={styles.testItem2}>
                      <FormDatePicker
                        dateFormat="MM/dd/yyyy"
                        dateString={data.test2.placeDate}
                        setDate={(value) => {
                          data.test2.placeDate = value;
                          setValue();
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className={styles.testItem}>
                    <label>Read Date</label>
                    <div className={styles.testItem2}>
                      <FormDatePicker
                        dateFormat="MM/dd/yyyy"
                        dateString={data.test2.readDate}
                        setDate={(value) => {
                          data.test2.readDate = value;
                          setValue();
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.testRow}>
                  <div className={styles.testItem}>
                    <label>Result in mm</label>
                    <div className={styles.testItem2}>
                      <InputBox
                        placeholder={""}
                        onChange={(e) => {
                          data.test2.resultText = e.target.value;
                          setValue();
                        }}
                        value={data.test2.resultText}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <div className={styles.testItem}>
                    <label>Result</label>
                    <div className={styles.testItem2}>
                      <DashboardRadio
                        isSelected={data.test2.result === TBTestResults.Neg}
                        onChange={(value) => {
                          data.test2.result = value;
                          setValue();
                        }}
                        value={TBTestResults.Neg}
                        label={TBTestResults.Neg}
                        containerClassName={styles.radioContainer}
                        disabled={disabled}
                      />
                      <DashboardRadio
                        isSelected={data.test2.result === TBTestResults.Pos}
                        onChange={(value) => {
                          data.test2.result = value;
                          setValue();
                        }}
                        value={TBTestResults.Pos}
                        label={TBTestResults.Pos}
                        containerClassName={styles.radioContainer}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {/* {(data.type === TBSubmissionTypes.B) && ( */}
            <DashboardRadio
              isSelected={data.type === TBSubmissionTypes.B}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={TBSubmissionTypes.B}
              label={TBSubmissionTypeDescriptions.B}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
          {data.type === TBSubmissionTypes.B && (
            <div className={styles.testRow}>
              <div className={styles.testItem}>
                <label>Date Drawn:</label>
                <div className={styles.testItem2}>
                  <FormDatePicker
                    dateFormat="MM/dd/yyyy"
                    dateString={data.dateDrawn}
                    setDate={(value) => {
                      data.dateDrawn = value;
                      setValue();
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={styles.testItem}>
                <label>Result:</label>
                <div className={styles.testItem2}>
                  <DashboardRadio
                    isSelected={data.result === TBTestResults.Neg}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={TBTestResults.Neg}
                    label={TBTestResults.Neg}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  <DashboardRadio
                    isSelected={data.result === TBTestResults.Pos}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={TBTestResults.Pos}
                    label={TBTestResults.Pos}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          {/* {(data.type === TBSubmissionTypes.C) && ( */}
            <DashboardRadio
              isSelected={data.type === TBSubmissionTypes.C}
              onChange={(value) => {
                setValue("type", value);
              }}
              value={TBSubmissionTypes.C}
              label={TBSubmissionTypeDescriptions.C}
              containerClassName={styles.radioContainer}
              disabled={disabled}
            />
          {/* )} */}
          {data.type === TBSubmissionTypes.C && (
            <div className={styles.testRow}>
              <div className={styles.testItem}>
                <label>TB Chest Xray Date:</label>
                <div className={styles.testItem2}>
                  <FormDatePicker
                    dateFormat="MM/dd/yyyy"
                    dateString={data.xRayDate}
                    setDate={(value) => {
                      data.xRayDate = value;
                      setValue();
                    }}
                    disabled={disabled}
                  />
                </div>
              </div>
              <div className={styles.testItem}>
                <label>Result:</label>
                <div className={styles.testItem2}>
                  <DashboardRadio
                    isSelected={data.result === TBTestResults.Neg}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={TBTestResults.Neg}
                    label={TBTestResults.Neg}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                  <DashboardRadio
                    isSelected={data.result === TBTestResults.Pos}
                    onChange={(value) => {
                      data.result = value;
                      setValue();
                    }}
                    value={TBTestResults.Pos}
                    label={TBTestResults.Pos}
                    containerClassName={styles.radioContainer}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
