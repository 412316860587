import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ImmunizationTdapView.module.css";

import { AddButton, InputBox } from "@cpnw/ui";
import DashboardRadio from "components/DashboardRadio";
import FormDatePicker from "components/FormDatePicker";

const SubmissionTypes = {
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  Military: "Military",
};

const SubmissionTypeDescriptions = {
  A: "A. Vaccination Dates:",
  B: "B. Immunity by Titer:",
  Military: "Military immunization",
};

export default function ImmunizationTdapView({
  data = {},
  onChange,
  disabled = false
}) {
  const setValue = (key, value) => {
    if (key) {
      data[key] = value;
    }

    if (onChange) {
      onChange({ ...data });
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.testRow}>
          A. Initial Tdap date {" "}
          <FormDatePicker
            dateFormat="MM/dd/yyyy"
            dateString={data.date1}
            setDate={(value) => {
              data.date1 = value;
              setValue();
            }}
            disabled={disabled}
          />
        </div>
        <div className={styles.testRow}>
          B. Td/Tdap subsequent dose date {" "}
          <FormDatePicker
            dateFormat="MM/dd/yyyy"
            dateString={data.date2}
            setDate={(value) => {
              data.date2 = value;
              setValue();
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
}
